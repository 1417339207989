// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"
import chiliBorderImage from "assets/img/icons/chili-pepper-3.svg"
import aboutDescriptionStyle from "assets/jss/material-kit-pro-react/views/aboutPageSections/aboutDescriptionStyle.jsx"
// nodejs library that concatenates classes
import classNames from "classnames"
// core components
import GridContainer from "components/Grid/GridContainer.jsx"
import GridItem from "components/Grid/GridItem.jsx"
import React from "react"

const useStyles = makeStyles(aboutDescriptionStyle)

export default function AboutDescription() {
  const classes = useStyles()
  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <h2 className={classes.title}>About Chilimania</h2>
        <GridContainer alignItems="center">
          <GridItem xs={12} sm={2} md={2}>
            <img
              className={classes.chiliPepperIcon}
              src={chiliBorderImage}
              alt="Chili Pepper"
            />
          </GridItem>
          <GridItem
            xs={12}
            sm={8}
            md={8}
            className={classNames(classes.mrAuto, classes.mlAuto)}
          >
            <h5
              className={classes.aboutDescription}
              style={{ borderImage: `url(${chiliBorderImage}) 100% round` }}
            >
              Chilimania is all about supporting our youth and community. We’re
              home to the Wisconsin State Championship CASI-sanctioned chili
              cook-off, a traditional chili cook-off, a salsa contest, public
              chili tasting, best Bloody Mary competition, 12 hours of live
              music, food, fun, and more.
            </h5>
          </GridItem>
          <GridItem xs={12} sm={2} md={2}>
            <img
              className={classes.chiliPepperIcon}
              src={chiliBorderImage}
              alt="Chili Pepper"
            />
          </GridItem>
        </GridContainer>
      </div>
    </div>
  )
}
