import {
  container,
  mrAuto,
  mlAuto,
  description,
  title,
  section,
  textCenter,
} from "assets/jss/material-kit-pro-react.jsx"

const aboutDescriptionStyle = {
  container,
  section: {
    ...section,
    padding: "80px 0px",
    ...textCenter,
  },
  title: {
    ...title,
    marginTop: "0",
  },
  textCenter,
  mrAuto,
  mlAuto,
  chiliPepperIcon: {
    ...mlAuto,
    ...mrAuto,
    width: "50%",
  },
  aboutDescription: {
    ...description,
    fontSize: "1.15rem",
    fontWeight: "400",
    color: "#777",
    border: "30px solid transparent",
    padding: "15px",
  },
}

export default aboutDescriptionStyle
